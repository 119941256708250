<script>
import { h, ref, computed } from "vue";
export default {
  name: "ShareNetwork",
  props: {
    /**
     * Name of the network to display.
     */
    network: {
      type: String,
      required: true,
    },

    /**
     * URL of the content to share.
     */
    url: {
      type: String,
      required: true,
    },

    /**
     * Title of the content to share.
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * Description of the content to share.
     */
    description: {
      type: String,
      default: "",
    },

    /**
     * Quote content, used for Facebook.
     */
    quote: {
      type: String,
      default: "",
    },

    /**
     * Hashtags, used for Twitter and Facebook.
     */
    hashtags: {
      type: String,
      default: "",
    },

    /**
     * Twitter user, used for Twitter
     * @var string
     */
    twitterUser: {
      type: String,
      default: "",
    },

    /**
     * Media to share, used for Pinterest
     */
    media: {
      type: String,
      default: "",
    },

    /**
     * HTML tag used by the Network component.
     */
    tag: {
      type: String,
      default: "a",
    },

    /**
     * Properties to configure the popup window.
     */
    popup: {
      type: Object,
      default: () => ({
        width: 626,
        height: 436,
      }),
    },
  },
  emits: ["change", "close", "open"],
  setup(props, { slots, emit }) {
    const AvailableNetworks = {
      baidu: "http://cang.baidu.com/do/add?iu=@u&it=@t",
      buffer: "https://bufferapp.com/add?text=@t&url=@u",
      email: "mailto:?subject=@t&body=@u%0D%0A@d",
      evernote: "https://www.evernote.com/clip.action?url=@u&title=@t",
      facebook: "https://www.facebook.com/sharer/sharer.php?u=@u&title=@t&description=@d&quote=@q&hashtag=@h",
      flipboard: "https://share.flipboard.com/bookmarklet/popout?v=2&url=@u&title=@t",
      hackernews: "https://news.ycombinator.com/submitlink?u=@u&t=@t",
      instapaper: "http://www.instapaper.com/edit?url=@u&title=@t&description=@d",
      line: "http://line.me/R/msg/text/?@t%0D%0A@u%0D%0A@d",
      linkedin: "https://www.linkedin.com/shareArticle?url=@u",
      messenger: "fb-messenger://share/?link=@u",
      odnoklassniki: "https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=@u&st.comments=@t",
      pinterest: "https://pinterest.com/pin/create/button/?url=@u&media=@m&description=@t",
      pocket: "https://getpocket.com/save?url=@u&title=@t",
      quora: "https://www.quora.com/share?url=@u&title=@t",
      reddit: "https://www.reddit.com/submit?url=@u&title=@t",
      skype: "https://web.skype.com/share?url=@t%0D%0A@u%0D%0A@d",
      sms: "sms:?body=@t%0D%0A@u%0D%0A@d",
      stumbleupon: "https://www.stumbleupon.com/submit?url=@u&title=@t",
      telegram: "https://t.me/share/url?url=@u&text=@t%0D%0A@d",
      tumblr: "https://www.tumblr.com/share/link?url=@u&name=@t&description=@d",
      twitter: "https://twitter.com/intent/tweet?text=@t&url=@u&hashtags=@h@tu",
      viber: "viber://forward?text=@t%0D%0A@u%0D%0A@d",
      vk: "https://vk.com/share.php?url=@u&title=@t&description=@d&image=@m&noparse=true",
      weibo: "http://service.weibo.com/share/share.php?url=@u&title=@t&pic=@m",
      whatsapp: "https://api.whatsapp.com/send?text=@t%0D%0A@u%0D%0A@d",
      wordpress: "https://wordpress.com/press-this.php?u=@u&t=@t&s=@d&i=@m",
      xing: "https://www.xing.com/social/share/spi?op=share&url=@u&title=@t",
      yammer: "https://www.yammer.com/messages/new?login=true&status=@t%0D%0A@u%0D%0A@d",
    };

    const popupTop = ref(0);
    const popupLeft = ref(0);
    const popupWindow = ref(null);
    const popupInterval = ref(null);

    const networks = computed(() => {
      return AvailableNetworks;
    });

    const key = computed(() => {
      return props.network.toLowerCase();
    });

    const rawLink = computed(() => {
      const ua = typeof navigator !== "undefined" ? navigator.userAgent.toLowerCase() : null;
      if (ua && key.value === "sms" && (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1)) {
        return networks.value[key.value].replace(":?", ":&");
      }
      return networks.value[key.value];
    });

    const encodedHashtags = computed(() => {
      if (key.value === "facebook" && props.hashtags.length) {
        return "%23" + encodeURIComponent(props.hashtags.split(",")[0]);
      }
      return encodeURIComponent(props.hashtags);
    });

    const shareLink = computed(() => {
      let link = rawLink.value;
      if (key.value === "twitter") {
        if (!props.hashtags.length) {
          link = link.replace("&hashtags=@h", "");
        }
        if (!props.twitterUser.length) {
          link = link.replace("@tu", "");
        }
      }
      const url = encodeURIComponent(props.url);
      const title = encodeURIComponent(props.title);
      const description = encodeURIComponent(props.description);
      const quote = encodeURIComponent(props.quote);
      const twitterUser = encodeURIComponent(props.twitterUser);
      const media = encodeURIComponent(props.media);
      return link
        .replace(/@tu/g, "&via=" + twitterUser)
        .replace(/@u/g, url)
        .replace(/@t/g, title)
        .replace(/@d/g, description)
        .replace(/@q/g, quote)
        .replace(/@h/g, encodedHashtags.value)
        .replace(/@m/g, media);
    });

    function resizePopup() {
      const width = window.innerWidth || document.documentElement.clientWidth || window.screenX;
      const height = window.innerHeight || document.documentElement.clientHeight || window.screenY;
      const systemZoom = width / window.screen.availWidth;

      popupLeft.value =
        (width - props.popup.width) / 2 / systemZoom +
        (window.screenLeft !== undefined ? window.screenLeft : window.screenX);
      popupTop.value =
        (height - props.popup.height) / 2 / systemZoom +
        (window.screenTop !== undefined ? window.screenTop : window.screenY);
    }

    function share() {
      resizePopup();
      if (popupWindow.value && popupInterval.value) {
        clearInterval(popupInterval.value);
        popupWindow.value.close();
        emit("change");
      }
      popupWindow.value = window.open(
        shareLink.value,
        "sharer-" + key.value,
        ",width=" +
          props.popup.width +
          ",height=" +
          props.popup.height +
          ",left=" +
          popupLeft.value +
          ",top=" +
          popupTop.value +
          ",screenX=" +
          popupLeft.value +
          ",screenY=" +
          popupTop.value +
          ",personalbar=0,toolbar=0,resizable=1"
      );
      // If popups are prevented (AdBlocker, Mobile App context..), popup.window
      // stays undefined and we can't display it, so we need to check if it exists.
      if (popupWindow.value) {
        popupWindow.value.focus();
        popupInterval.value = setInterval(() => {
          if (!popupWindow.value || popupWindow.value.closed) {
            clearInterval(popupInterval.value);
            popupWindow.value = null;
            emit("close");
          }
        }, 500);
        emit("open");
      }
    }

    function touch() {
      window.open(shareLink.value, "_blank");
      emit("open");
    }

    return () => {
      return h(
        props.tag,
        {
          className: "share-network-" + key.value,
          href: props.tag === "a" ? "javascript:void(0)" : null,
          onClick: () => {
            rawLink.value.substring(0, 4) === "http" ? share() : touch();
          },
          // rel: "noopener noreferrer",
        },
        slots.default()
      );
    };
  },
};
</script>
