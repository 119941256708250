import { createStore } from 'vuex'
import {
  useApiCallElements,
  useApiCallHome,
  useApiCallFooter,
  useApiCallVideoByFilter,
  useApiCallCTByContains,
  useApiCallPage,
  useApiCallThemes,
  useApiCallRegions,
  useApiCallEthnicGroups,
  useApiCallDossiers,
  useApiCallDossier,
  useApiCallVideos,
  useApiCallEthnicGroup,
  useApiCallRegion,
  useApiCallTheme,
  useApiCallVideo,
  useCallDossierVideoConcordance

} from '@/use/apiCall.js'
// import axios from 'axios'
const apiBase = 'https://cms.ek-north.org/json/'

export default createStore({
  state: {
    loading: 0,
    lang: 'de',
    languages: ["de", "ru", "en"],
    elements: null,
    homepage: null,
    footer: null,
    featuredVideos: null,
    page: {
      themen: null,
      regionen: null,
      ethnien: null,
      dossiers: null
    },
    dossier: null,
    ethnicGroup: null,
    region: null,
    theme: null,
    video: null,
    themes: null,
    regions: null,
    ethnicGroups: null,
    dossiers: null,
    viewport: {
      width: null,
      height: null,
      contentAreaHeight: null
    }
  },
  getters: {
    lang(state) {
      return state.lang
        ? state.lang
        : undefined
    },
    languages: (state) => state.languages,
    loading(state) {
      return state.loading
    },
    elements(state) {
      return state.elements
        ? state.elements.elements
        : undefined
    },
    featuredVideos(state) {
      return state.featuredVideos
        ? state.featuredVideos
        : undefined
    },
    homepage(state) {
      return state.homepage
        ? state.homepage
        : undefined
    },
    footer(state) {
      return state.footer
        ? state.footer
        : undefined
    },
    themes(state) {
      return state.themes
        ? state.themes
        : undefined
    },
    regions(state) {
      return state.regions
        ? state.regions
        : undefined
    },
    ethnicGroups(state) {
      return state.ethnicGroups
        ? state.ethnicGroups
        : undefined
    },
    dossiers(state) {
      return state.dossiers
        ? state.dossiers
        : undefined
    },
    dossier(state) {
      return state.dossier
        ? state.dossier
        : undefined
    },
    ethnicGroup(state) {
      return state.ethnicGroup
        ? state.ethnicGroup
        : undefined
    },
    region(state) {
      return state.region
        ? state.region
        : undefined
    },
    theme(state) {
      return state.theme
        ? state.theme
        : undefined
    },
    video(state) {
      return state.video
        ? state.video
        : undefined
    },
    pageThemes(state) {
      return state.page.themen
        ? state.page.themen
        : undefined
    },
    pageRegions(state) {
      return state.page.regionen
        ? state.page.regionen
        : undefined
    },
    pageEthnicGroups(state) {
      return state.page.ethnicGroups
        ? state.page.ethnicGroups
        : undefined
    },
    pageDossiers(state) {
      return state.page.dossiers
        ? state.page.dossiers
        : undefined
    }
  },
  mutations: {
    setLoading(state, payload) {
      state.loading += payload
    },
    setLang(state, payload) {
      state.lang = payload
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem('lang', payload);
      }
    },
    setLanguage(state, payload) {
      state.lang = payload;
    },
    setElements(state, payload) {
      state.elements = payload
    },
    setHomepage(state, payload) {
      state.homepage = payload
    },
    setFooterContent(state, payload) {
      state.footer = payload
    },
    setThemes(state, payload) {
      state.themes = payload
    },
    setRegions(state, payload) {
      state.regions = payload
    },
    setEthnicGroups(state, payload) {
      state.ethnicGroups = payload
    },
    setDossiers(state, payload) {
      state.dossiers = payload
    },
    setFeaturedVideos(state, payload) {
      state.featuredVideos = payload
    },
    setPage(state, payload) {
      state.page[payload.key] = payload.content
    },
    setDossier(state, payload) {
      state.dossier = payload
    },
    setEthnicGroup(state, payload) {
      state.ethnicGroup = payload
    },
    setRegion(state, payload) {
      state.region = payload
    },
    setTheme(state, payload) {
      state.theme = payload
    },
    setVideo(state, payload) {
      state.video = payload
    }
  },
  actions: {
    setLang({ commit }, payload ) {
      commit('setLang', payload)
    },
    async getElements({ commit }){
      try {
        commit('setLoading', 1)
        const response = await useApiCallElements()
        commit('setElements', response)
        // commit('setLoading', -1)
        return 'done'
      } catch (error) {
        console.log('getElements error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    async getHomepage({ commit }){
      try {
        commit('setLoading', 1)
        const response = await useApiCallHome()
        commit('setHomepage', response)
        // commit('setLoading', -1)
        return 'done'
      } catch (error) {
        console.log('getHompage error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    async getFooterContent({ commit }){
      try {
        commit('setLoading', 1)
        const response = await useApiCallFooter()
        commit('setFooterContent', response)
        // commit('setLoading', -1)
        return 'done'
      } catch (error) {
        console.log('getFooterContent error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    async getThemes({ commit }){
      try {
        commit('setLoading', 1)
        const response = await useApiCallThemes()
        commit('setThemes', response)
        // commit('setLoading', -1)
        return 'done'
      } catch (error) {
        console.log('getThemes error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    async getRegions({ commit }){
      try {
        commit('setLoading', 1)
        const response = await useApiCallRegions()
        commit('setRegions', response)
        // commit('setLoading', -1)
        return 'done'
      } catch (error) {
        console.log('getRegions error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    async getEthnicGroups({ commit }){
      try {
        commit('setLoading', 1)
        const response = await useApiCallEthnicGroups()
        commit('setEthnicGroups', response)
        // commit('setLoading', -1)
        return 'done'
      } catch (error) {
        console.log('getEthnnicGroups error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    async getDossiers({ commit }){
      try {
        commit('setLoading', 1)
        const response = await useApiCallDossiers()
        commit('setDossiers', response)
        // commit('setLoading', -1)
        return 'done'
      } catch (error) {
        console.log('getDossiers error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    async getFeaturedVideos({ commit }, payload){
      try {
        commit('setLoading', 1)
        const response = await useApiCallVideoByFilter(payload.field, payload.number)

        commit('setFeaturedVideos', response)
        // commit('setLoading', -1)
        return 'done'
      } catch (error) {
        console.log('getFeaturedVideo error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    async getPage({ commit }, payload) {
      try {
        commit('setLoading', 1)
        const response = await useApiCallPage(payload)

        commit('setPage', { key: payload, content:response })
        // commit('setLoading', -1)
        return 'done'
      } catch (error) {
        console.log('getPage error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    async getDossier({ commit }, payload) {
      try {
        commit('setLoading', 1)
        const response = await useApiCallDossier(payload)
        // extrahiere die zugehörigen VideoIds
        let videoIds = null
        if (response.videos[0].video) {
            videoIds = response.videos.map(v => v.video)
        }
        response.dossierVideos = videoIds
          ? await useApiCallVideos(videoIds)
          : undefined
        commit('setDossier', response)
        // commit('setLoading', -1)
        return 'done'
      } catch (error) {
        console.log('getDossier error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    async getEthnicGroup({ commit }, payload) {
      try {
        commit('setLoading', 1)
        const response = await useApiCallEthnicGroup(payload)
        const relatedVideos = await useApiCallCTByContains('video','ethnic_group', response.id)
        const relatedDossiers = await useApiCallCTByContains('dossier','ethnic_group', response.id)
        response.relatedDossiers = relatedDossiers
        response.relatedVideos = relatedVideos
        commit('setEthnicGroup', response)
        // commit('setLoading', -1)
        return 'done'
      } catch(error) {
        console.log('getEthnicGroup error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    async getRegion({ commit }, payload) {
      try {
        commit('setLoading', 1)
        const response = await useApiCallRegion(payload)
        const relatedVideos = await useApiCallCTByContains('video','region', response.id)
        const relatedDossiers = await useApiCallCTByContains('dossier','region', response.id)
        response.relatedDossiers = relatedDossiers
        response.relatedVideos = relatedVideos
        commit('setRegion', response)
        // commit('setLoading', -1)
        return 'done'
      } catch(error) {
        console.log('getRegion error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    async getTheme({ commit }, payload) {
      try {
        commit('setLoading', 1)
        const response = await useApiCallTheme(payload)
        const relatedVideos = await useApiCallCTByContains('video','theme', response.id)
        const relatedDossiers = await useApiCallCTByContains('dossier','theme', response.id)
        response.relatedDossiers = relatedDossiers
        response.relatedVideos = relatedVideos
        commit('setTheme', response)
        // commit('setLoading', -1)
        return 'done'
      } catch(error) {
        console.log('getTheme error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    async getVideo({ commit }, payload) {
      try {
        commit('setLoading', 1)
        let response = await useApiCallVideo(payload)
        commit('setVideo', response)
        // commit('setLoading', -1)
        return 'done'
      } catch(error) {
        console.log('getVideo error', error)
      } finally {
        commit('setLoading', -1)
      }
    },
    changeLanguage({ commit }, lang) {
      commit("setLanguage", lang);
    },
  },
  modules: {
  }
})
