<template>
  <div class="image-header">
    <!-- <img src="./assets/Header_n_2.jpg" > -->
  </div>
  <div class="title-header">
    <!-- <img src="./assets/Header_n_2.jpg" > -->
  </div>
  <div class="title">
    <div class="title-box">
      <h1><span class="main">Environmental Knowledge</span><span class="sub"><img src="/img/Rentier_roh.svg">of the North</span></h1>
    </div>
  </div>
  <Suspense>
    <template #default>
      <div class="nav-header"><Navigation /></div>
    </template>
    <template #fallback>
      Loading ...
    </template>
  </Suspense>

  <router-view/>
  <Suspense>
    <template #default>
      <Footer />
    </template>
    <template #fallback>
      Loading ...
    </template>
  </Suspense>
</template>

<script>
import Navigation from '@/components/parts/Navigation.vue'
import Footer from '@/components/parts/Footer.vue'
import { useStore } from 'vuex'
import { computed, onServerPrefetch, onMounted } from 'vue'

export default {
  name: 'Application',
  components: {
    Navigation,
    Footer
  },
  setup (props, context) {
    const store = useStore()

    const loading = computed(() => store.getters.loading)

    const fetchElements = async () => {
      await store.dispatch('getElements')
    }

    const elements = computed(() => store.getters.elements)

    onServerPrefetch(async () => {
      await fetchElements();
    })
    onMounted(async () => {
      if (!elements.value) {
        await fetchElements();
      }
    })

    // setzt lang auf den in der SessionStorage gespeicherten Wert
    if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('lang')) {
      store.dispatch('setLang', sessionStorage.getItem('lang'))
    }
    // Setzt die Sprache dieser Session auf die Nutzerpräferenz des Browsers,
    // wenn bei dieser Session noch keine manuelle Sprachwahl vorgenommen wurde
    let preSetLang = 'de'
    let userLang = preSetLang
    if (typeof navigator !== 'undefined') {
      userLang = navigator.language || navigator.userLanguage;
    }
    if(typeof sessionStorage !== 'undefined') sessionStorage.setItem('userLang', userLang)
    if(userLang.startsWith('en-')) preSetLang = 'en'
    if(userLang.startsWith('ru-')) preSetLang = 'ru'
    if(typeof sessionStorage !== 'undefined' && !sessionStorage.getItem('lang')) sessionStorage.setItem('lang', preSetLang)
  }
}
</script>

<style lang="scss">
@import 'styles/main.scss';
$imgPath: "@/assets/img/";
#app {
  // regelt die Ausgangsposition der Navbar
  margin-top: 13rem;
  @media screen and (max-width: 800px) {
    margin-top: 7rem;
  }
  .image-header {
    position: fixed;
    height: 14rem;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
    background-image: url('#{$imgPath}Header_BG_1.jpg');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (max-width: 1000px) {
      background-image: url('#{$imgPath}Header_BG_3.jpg');
    }
    @media screen and (max-width: 800px) {
      display: none
    }
  }
  .title-header {
    position: fixed;
    height: 7rem;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background-image: url('#{$imgPath}Header_BG_1.jpg');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (max-width: 1000px) {
      background-image: url('#{$imgPath}Header_BG_3.jpg');
    }
  }
  .title {
      position: fixed;
      top: .25rem;
      left: 0;
      right: 0;
      z-index: 100;
      display: flex;
      justify-content: center;
    .title-box {
      display: inline-block;
      h1 {
        display: inline-block;
        color: $complementary;
        font-size: 2.25rem;
        opacity: 1;
        margin-top: .5rem;
        @media screen and (max-width: 800px) {
          font-size: 1.75rem;
        }
        @media screen and (max-width: 420px) {
          font-size: 1.25rem;
        }
        span {
          display: block;
          &.main {
            text-transform: none;
            text-shadow: 3px 6px 8px rgba(200,200,200,.75);
          }
          &.sub {
            text-transform: uppercase;
            letter-spacing: .15rem;
            text-shadow: 3px 6px 8px rgba(200,200,200,.75);
            color: $complementary;
            display: flex;
            justify-content: space-between;
            font-size: 1.6rem;
            // font-style: italic;
            @media screen and (max-width: 800px) {
              font-size: 1.25rem;
            }
            @media screen and (max-width: 400px) {
              font-size: .9rem;
            }
            img {
              display: block;
              padding-left: 0;
              max-width: 5rem;
              margin: -1.25rem 0 0 -1rem;
              @media screen and (max-width: 800px) {
                max-width: 3.6rem;
                margin: -.75rem 0 0 -.5rem;
              }
              @media screen and (max-width: 400px) {
                max-width: 2.4rem;
                margin: -.5rem 0 0 -.25rem;
              }
            }

          }
        }
      }
    }
  }
  .nav-header {
    position: sticky;
    // regelt nur das Sticky-Verhalten
    top: 7rem;
    height: 2.5rem;
    // background-color: lighten($main_dark, 14%);
    background-color: $main_dark;
    // border-bottom: 2px solid $main_lateral;
    color: #fff;
    z-index: 999;
  }
}

</style>
