<template>
  <div class="nav-container">
    <div class="_nav" v-if="elements">
      <div class="_menu _mobile">
        <div class="_hamburger"
          @click="toggleMobileMenu()"
        >
          <div class="_line"></div><div class="_line"></div><div class="_line"></div>
        </div>
        <div class="_dropdown"
          v-if="mobileMenu"
        >
          <router-link @click="toggleMobileMenu()" :to="getLink('')">{{translate(getElement('navi_homepage'), 'text')}}</router-link>
          <router-link @click="toggleMobileMenu()" :to="getLink('/themes')">{{translate(getElement('navi_themes'), 'text')}}</router-link>
          <router-link @click="toggleMobileMenu()" :to="getLink('/regions')">{{translate(getElement('navi_regions'), 'text')}}</router-link>
          <router-link @click="toggleMobileMenu()" :to="getLink('/ethnic-groups')">{{translate(getElement('navi_ethnic_groups'), 'text')}}</router-link>
          <router-link @click="toggleMobileMenu()" :to="getLink('/dossiers')">{{translate(getElement('navi_dossiers'), 'text')}}</router-link>
        </div>
      </div>
      <div class="_menu _desktop">
        <router-link :to="getLink('')">{{translate(getElement('navi_homepage'), 'text')}}</router-link>
        <router-link :to="getLink('/themes')">{{translate(getElement('navi_themes'), 'text')}}</router-link>
        <router-link :to="getLink('/regions')">{{translate(getElement('navi_regions'), 'text')}}</router-link>
        <router-link :to="getLink('/ethnic-groups')">{{translate(getElement('navi_ethnic_groups'), 'text')}}</router-link>
        <router-link :to="getLink('/dossiers')">{{translate(getElement('navi_dossiers'), 'text')}}</router-link>
      </div>
      <div class="_lang">
        <router-link class="clickable" v-for="lang in languages" :key="lang" :to="getLanguageLink(lang)">
          {{ lang.toUpperCase() }}
        </router-link>
      </div>

    </div>
    <div
      class="social-media-icons"
    >

      <!-- <ShareNetwork
        @open="open"
        network="facebook"
        url="https://ek-north.org"
        title="Indigenes Umweltwissen und nachhaltige Naturbeziehungen in Zeiten des Klimawandels"
        hashtags="sibiria"
      >
        <img class="share-icon" src="../../assets/icons/fb.png" alt="share on facebook">
      </ShareNetwork> -->

      <ShareNetwork
        network="facebook"
        :url="currentUrl"
        hashtags="sibiria"
      >
        <img class="share-icon" src="../../assets/icons/fb.png" alt="share on facebook">
      </ShareNetwork>
      <ShareNetwork
        network="pinterest"
        :url="currentUrl"
        hashtags="sibiria"
      >
        <img class="share-icon" src="../../assets/icons/pr.png" alt="share on pinterest">
      </ShareNetwork>
      <ShareNetwork
        network="twitter"
        :url="currentUrl"
        hashtags="sibiria"
      >
        <img class="share-icon" src="../../assets/icons/Twitter.png" alt="share on Twitter">
      </ShareNetwork>
      <ShareNetwork
        network="vk"
        :url="currentUrl"
        hashtags="sibiria"
      >
        <img class="share-icon" src="../../assets/icons/VK.png" alt="share on VK">
      </ShareNetwork>

    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed, onMounted } from 'vue'
// import { useApiCallElements } from '@/use/apiCall.js'
import { useRoute } from "vue-router";
import ShareNetwork from "../ShareNetwork";
export default {
  name: 'navigation',
  components: {
    ShareNetwork
  },
  async setup() {
    const store = useStore()
      // speichert die jeweils aktuellen Viewport-Dimensionen im Store
    const navPosY = ref()
    const elements = computed(() => {
      return store.getters.elements
    })
    const route = useRoute();

    const currentUrl = computed(() => (typeof window !== "undefined") ? window.location.href.split('#')[0] : `https://ek-north.org${route.fullPath}`);

    onMounted(() => {
      store.state.viewport.width = window.innerWidth
      store.state.viewport.height = window.innerHeight
      window.addEventListener('resize', () => {
        store.state.viewport.height = window.innerHeight
        store.state.viewport.width = window.innerWidth
      })
    })

    const lang = computed(() => {
        return store.state.lang
    })

    function getElement(handle) {
        return elements.value.find(element => {
              return element.handle === handle
          })
    }

    function translate(content, el) {
        return content[`${el}_${lang.value}`]
            ? content[`${el}_${lang.value}`]
            : 'Sprachversion fehlt'
    }
    // function setLang(lang) {
    //   store.dispatch('setLang', lang)
    // }
    const mobileMenu = ref(false)
    function toggleMobileMenu() {
      mobileMenu.value = !mobileMenu.value
    }

    function getLink(url) {
      return `${url}/${lang.value}`;
    }

    function getLanguageLink(lang) {
      return {
        name: route.name,
        hash: route.hash,
        params: { lang: lang },
      };
    }
    return {
      // setLang,
      getElement,
      translate,
      mobileMenu,
      toggleMobileMenu,
      elements,
      getLink,
      getLanguageLink,
      languages: computed(() => store.state.languages),
      currentUrl,
    }
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/vars.scss';
.nav-container {
  position: relative;
  .social-media-icons {
    position: absolute;
    top: -2.5rem;
    right: 1.5rem;
    display: flex;
    gap: .6rem;
    padding: .25rem;
    background-color: rgba(255,255,255, .75);
    border-radius: 4px;
  @media screen and (max-width: 800px) {
    top: 3rem;
  }
  img.share-icon {
      max-width: 1.5rem;
      display: block;
    }
  }
  ._nav {
    display: flex;
    position: relative;
    justify-content: space-between;
    height: 2.5rem;
    align-items: center;
    padding: 0 3rem;
    ._menu {
      &._mobile {
        display: none;
        ._hamburger {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          background-color: lighten($main_dark, 5%);
          width: 3rem;
          height: 1.75rem;
          padding: 6px 8px;
          border-radius: 4px;
          ._line {
            background-color: $inverse;
            width: 100%;
            height: 2px;
          }
        }
        ._dropdown {
          position: absolute;
          display: flex;
          flex-direction: column;
          top: 2.5rem;
          left: 0;
          right: 0;
          padding: 1rem 3rem;
          background-color: #fff;
          overflow: auto;
          a {
            color: $main_dark;
            padding: .2rem;
            &:hover {
              color: darken($main_dark, 15%);
              background-color: rgba(0,0,0,.05);
            }
          }
        }
        @media screen and (max-width: 800px) {
          display: block;
        }
      }
      &._desktop {
        display: block;
      @media screen and (max-width: 800px) {
        display: none;
      }
      }
      a {
        display: inline-block;
        margin-right: 1rem;
        color: $inverse;
        font-size: 1.1rem;
        text-decoration: none;
        &:hover {
          color: darken($inverse, 10%);
        }
      }
    }
    ._lang {
      a {
        display: inline-block;
        padding-left: .5rem;
        color: darken($inverse, 10%);
        text-decoration: none;
        &.router-link-active {
          // cursor: pointer;
          &:hover {
            color: darken($inverse, 10%);
          }
        }
      }
    }
  }
}
</style>
