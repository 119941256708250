import axios from 'axios'
// import { langs, dossierConfig } from '@/config/entities.js'

const apiBase = 'https://cms.ek-north.org/json/'

// Bereinigung um nach dem Mapping nicht mehr benötigte Daten
const finalDeletes = [
    'attributes', 'datecreated', 'datechanged', 'datepublish', 'datedepublish'
]

function mapItem(data) {
    const item = {}
        item.id = data.id
        for (let attr in data.attributes) {
            item[attr] = data.attributes[attr]
        }
        item.relevantDate = item.datechanged
            ? item.datechanged
            : item.datepublish
        finalDeletes.forEach(d => delete item[d])
        return item
}

function basicMapping(responseData) {
    if(Array.isArray(responseData)) {
        return responseData.map(data => {
            return mapItem(data)
        })
    } else {
        return mapItem(responseData)
    }
}

export async function useApiCallHome() {
    const response = await axios.get(apiBase + 'homepage/')
    return basicMapping(response.data.data)[0]
}
export async function useApiCallPage(slug) {
    const response = await axios.get(apiBase + `pages/${slug}`)
    return basicMapping(response.data.data)
}
export async function useApiCallElements() {
    const response = await axios.get(apiBase + 'homepage/', {
        params: {
          'fields[homepage]': 'elements'
        }
    })
    return basicMapping(response.data.data)[0]
}

export async function useApiCallFooter() {
    const response = await axios.get(apiBase + 'footer_content')
    return basicMapping(response.data.data)
}

export async function useApiCallDossiers() {
    const response = await axios.get(apiBase + 'dossier', {
        params: {
          'page[size]': '2000'
        }
    })
    return basicMapping(response.data.data)
}

export async function useApiCallDossier(slug) {
    const response = await axios.get(apiBase + 'dossier/' + slug)
    return basicMapping(response.data.data)
}

export async function useApiCallThemes() {
    const response = await axios.get(apiBase + 'theme', {
        params: {
            'page[size]': '2000'
        }
    })
    return basicMapping(response.data.data)
}
export async function useApiCallRegions() {
    const url = apiBase + 'region'
    const response = await axios.get(url, {
        params: {
            'page[size]': '2000'
        }
    })
    return basicMapping(response.data.data)
}

export async function useApiCallEthnicGroups() {
    const url = apiBase + 'ethnic_group'
    // console.log('url', url)
    const response = await axios.get(url, {
        params: {
            'page[size]': '2000'
        }
    })
    return basicMapping(response.data.data)
}

export async function useApiCallEthnicGroup(slug) {
    const url = apiBase + 'ethnic_group/' + slug
    const response = await axios.get(url)
    return basicMapping(response.data.data)
}
export async function useApiCallRegion(slug) {
    const url = apiBase + 'region/' + slug
    const response = await axios.get(url)
    return  basicMapping(response.data.data)
}
export async function useApiCallTheme(slug) {
    const url = apiBase + 'theme/' + slug
    const response = await axios.get(url)
    return basicMapping(response.data.data)
}
export async function useApiCallVideo(slug) {
    const url = apiBase + 'video/' + slug
    const response = await axios.get(url)
    let mapped = basicMapping(response.data.data)
    mapped.vimeo_id = mapped.vimeo_link
        ? mapped.vimeo_link.replace('https://vimeo.com/','')
        : undefined
    const concUrl = apiBase + 'dossier/'
    const conc = await axios.get(concUrl, {
        params: {
            'fields[dossier]': 'videos',
            'page[size]': '2000'
        }
    })
    const relatedDossiersIds = conc.data.data.filter(d => {
            let match = false
            d.attributes.videos.forEach(v => {
                if(v.video === mapped.id) match = true
            })
            return match
        })
        .map(relDos => relDos.id)
    const relatedDossiers = await axios.get(apiBase + 'dossier', {
        params: {
          'page[size]': '2000',
          'filter[id]': relatedDossiersIds
        }
    })
    mapped.relatedDossiers = basicMapping(relatedDossiers.data.data)
    return mapped
}

export async function callDossierVideoConcordance() {
    // const url = apiBase + 'dossier/'
    // const response = await axios.get(url, {
    //     params: {
    //         'fields[dossier]': 'videos',
    //         'page[size]': '2000'
    //     }
    // })
    // return response.data.data
    return 'conc'
}

export async function useApiCallVideos(ids) {
    const idListing = ids
        ? ids.join(',')
        : '' // kein ids-Parameter, also alle Videos
    const response = await axios.get(apiBase + 'video', {
        params: {
          'page[size]': '2000',
          'filter[id]': idListing
        }
    })
    return basicMapping(response.data.data)
        // spezifische Ersetzung: extrahiere Vimeo-ID
        .map(item => {
            item.vimeo_id = item.vimeo_link
                ? item.vimeo_link.replace('https://vimeo.com/','')
                : undefined
            return item
        })
}

export async function useApiCallVideoByFilter(field,value) {
    const response = await axios.get(apiBase + 'video/', {
        params: {
          [`filter[${field}]`]: value            }
        })
    return basicMapping(response.data.data)
    // spezifische Ersetzung: extrahiere Vimeo-ID
    .map(item => {
        item.vimeo_id = item.vimeo_link
            ? item.vimeo_link.replace('https://vimeo.com/','')
            : undefined
        return item
    })[0]
}
export async function useApiCallCTByContains(type,field,value) {
    const response = await axios.get(`${apiBase}${type}`, {
        params: {
          [`contains[${field}]`]: value            }
        })
    let result = basicMapping(response.data.data)

    if (type === 'video') {
        // spezifische Ersetzung: extrahiere Vimeo-ID
        result = [...result].map(item => {
            item.vimeo_id = item.vimeo_link
                ? item.vimeo_link.replace('https://vimeo.com/','')
                : undefined
            return item
        })
    }
    return result
}
