<template>
    <div class="footer" v-if="!loading && footerContent">
        <div class="footer-content">
            <div class="info">
                <h2>{{ translate(getElement('footer_heading_info'), 'text') }}</h2>
                <div class="footer-content-element"
                    v-for="(element, index) in getFooterContent('info')"
                    :key="`info-element-${index}`"
                    v-html="translate(element, 'content')"
                />
            </div>
            <div class="contact">
                <h2>{{ translate(getElement('footer_heading_contact'), 'text') }}</h2>
                <div class="footer-content-element"
                    v-for="(element, index) in getFooterContent('contact')"
                    :key="`info-element-${index}`"
                    v-html="translate(element, 'content')"
                />
            </div>
            <div class="partners">
                <h2>{{ translate(getElement('footer_heading_partners'), 'text') }}</h2>
                <div class="footer-content-element"
                    v-for="(element, index) in getFooterContent('partner')"
                    :key="`info-element-${index}`"
                >
                    <div class="partner-container">
                        <div class="partner-name">
                            <a :href="translate(element, 'logo_link')" target="_blank">
                                {{ translate(element, 'title') }}
                            </a>
                        </div>
                        <div class="partner-logo">
                            <a :href="translate(element, 'logo_link')" target="_blank">
                                <img :src="element.logo.url" :alt="`Logo ${translate(element, 'title')}`" />
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            <div class="funding">
                <h2>{{ translate(getElement('footer_heading_funding'), 'text') }}</h2>
                <div class="footer-content-element"
                    v-for="(element, index) in getFooterContent('funding')"
                    :key="`info-element-${index}`"
                >
                    <div class="partner-container">
                        <div class="partner-name">
                            <!-- <a :href="translate(element, 'logo_link')" target="_blank">
                                {{ translate(element, 'title') }}
                            </a> -->
                            <p class="funding-claim" v-html="translate(element, 'logo_label')"/>
                        </div>
                        <div class="partner-logo">
                            <a :href="translate(element, 'logo_link')" target="_blank">
                                <img :src="element.logo.url" :alt="`Logo ${translate(element, 'title')}`" />
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, onServerPrefetch } from 'vue'
import { useStore } from 'vuex'
// import { useApiCallFooter, useApiCallElements } from '@/use/apiCall.js'

export default {
    name: 'Footer',
    async setup() {
        const store = useStore()
        const lang = computed(() => {
            return store.state.lang
        })

        const loading = computed(() => store.getters.loading)

        onServerPrefetch(async () => {
            await fetchFooterContent()
            if (!elements.value) {
                await fetchElements()
            }
        })
        onMounted(async () => {
            if(!footerContent.value) {
                await fetchFooterContent()
            }
            if (!elements.value) {
                await fetchElements()
            }
        })
        const fetchFooterContent = async () => {
          await store.dispatch('getFooterContent')
        }
        const fetchElements = async () => {
            await store.dispatch('getElements')
        }
        const footerContent  = computed(() => store.getters.footer)
        const elements = computed(() => store.getters.elements)


        function translate(content, el) {
            return content
                ? content[`${el}_${lang.value}`]
                : undefined
        }


        function getElement(handle) {
            return elements.value.find(element => {
                return element.handle === handle
            })
        }

        function getFooterContent(category) {
            return footerContent.value
                ? footerContent.value.filter(content => {
                    return content.insert_code === category
                })
                : undefined
        }


        return {
            loading,
            lang,
            translate,
            getFooterContent,
            elements,
            getElement,
            footerContent
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/vars.scss';

.footer {
    min-height: 8rem;
    background-color: $footer_bg;
    color: $inverse;
    border-top: 2px solid $main_dark;
    .footer-content {
        display: grid;
        grid-template-columns: 1fr 1fr 2fr 2fr;
        gap: 2rem;
        justify-content: space-between;
        max-width: 100rem;
        margin: auto;
        padding: 2rem 4rem;
        line-height: 140%;
        @media screen and (max-width: 1200px) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: 800px) {
            grid-template-columns: 1fr;
        }
        &>div {
            flex-basis: 33.33%;
        }
        .funding-claim,
        .partner-name {
          font-size: .75rem;
        }
    }
    a {
        color: darken($inverse, 10%);
        text-decoration: none;
        &:hover {
            color: darken($inverse, 25%);
        }
    }
    h2 {
        font-size: 1.5rem;
        font-weight: normal;
        margin-bottom: 1.5rem;
        color: $main;
    }
    .partner-container {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        margin-bottom: 1rem;
        &>div {
            flex-basis: 50%;
        }
        .partner-logo {
            img {
                display: block;
                width: 8rem;
            }
        }

    }
}
</style>
