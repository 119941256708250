import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";
// import Home from '../views/Home.vue'

const routes = [
  {
    path: "/",
    redirect: "/" + store.state.lang,
  },
  {
    path: `/:lang(${store.state.languages.join("|")})`,
    name: 'Home',
    // component: Home
    // redirect: '/dossier/sommerlager-der-rentierhirten'
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: `/project/:lang(${store.state.languages.join("|")})`,
    name: 'Project',
    component: () => import(/* webpackChunkName: "Project" */ '../views/Project.vue')
  },
  {
    path: `/imprint/:lang(${store.state.languages.join("|")})`,
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "Impressum" */ '../views/Imprint.vue')
  },
  {
    path: `/privacy/:lang(${store.state.languages.join("|")})`,
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ '../views/Privacy.vue')
  },
  {
    path: `/dossier/:slug/:lang(${store.state.languages.join("|")})`,
    name: 'Dossier',
    component: () => import(/* webpackChunkName: "Dossier" */ '../views/DossierPage.vue')
  },
  {
    path: `/dossiers/:lang(${store.state.languages.join("|")})`,
    name: 'Dossiers',
    component: () => import(/* webpackChunkName: "Dossiers" */ '../views/DossiersPage.vue')
  },
  {
    path: `/themes/:lang(${store.state.languages.join("|")})`,
    name: 'Themes',
    component: () => import(/* webpackChunkName: "Themes" */ '../views/ThemesPage.vue')
  },
  {
    path: `/theme/:slug/:lang(${store.state.languages.join("|")})`,
    name: 'Theme',
    component: () => import(/* webpackChunkName: "Theme" */ '../views/ThemePage.vue')
  },
  {
    path: `/regions/:lang(${store.state.languages.join("|")})`,
    name: 'Regions',
    component: () => import(/* webpackChunkName: "Regions" */ '../views/RegionsPage.vue')
  },
  {
    path: `/region/:slug/:lang(${store.state.languages.join("|")})`,
    name: 'Region',
    component: () => import(/* webpackChunkName: "Region" */ '../views/RegionPage.vue')
  },
  {
    path: `/ethnic-groups/:lang(${store.state.languages.join("|")})`,
    name: 'EthnicGroups',
    component: () => import(/* webpackChunkName: "EthnicGroups" */ '../views/EthnicGroupsPage.vue')
  },
  {
    path: `/ethnic-group/:slug/:lang(${store.state.languages.join("|")})`,
    name: 'EthnicGroup',
    component: () => import(/* webpackChunkName: "EthnicGroup" */ '../views/EthnicGroupPage.vue')
  },
  {
    path: `/video/:slug/:lang(${store.state.languages.join("|")})`,
    name: 'Video',
    component: () => import(/* webpackChunkName: "Video" */ '../views/VideoPage.vue')
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue")
  },
]

export default function (history) {
  const router = createRouter({
    history: history,
    scrollBehavior () {
      return {
        left: 0,
        top: 0
      }
    },
    routes,
  });
  router.afterEach((to) => {
    if (to.params && to.params.lang && store.getters.lang !== to.params.lang) {
      store.dispatch("changeLanguage", to.params.lang);
    }
  });
  return router;
}
