import { createSSRApp } from "vue";
import { createWebHistory } from "vue-router";
import createRouter from "./router";
import App from "./App.vue";
import store from "./store";
import { createHead } from "@vueuse/head";
// import "./registerServiceWorker";

const router = createRouter(createWebHistory());
const app = createSSRApp(App);
const head = createHead();

app.use(router);
app.use(store);
app.use(head);

(async (router, app, store) => {
  const storeInitialState = window.INITIAL_DATA;
  await router.isReady();
  if (storeInitialState) {
    store.replaceState(storeInitialState);
  }
  app.mount("#app", true);
})(router, app, store);
